import React from "react";
import PropTypes from "prop-types";

const Footer = ({ footerClass }) => (
  <div className={`defaultFooter ${footerClass}`}>
    <p>
      By{" "}
      <a
        href="https://www.linkedin.com/in/matthew-brimmer-5b3342bb/"
        target="_blank"
      >
        Matthew Brimmer
      </a>
    </p>
  </div>
);
Footer.propTypes = {
  footerClass: PropTypes.string,
};
export default Footer;
